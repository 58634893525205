import React from "react"

import Layout from "layouts/default"
import ContentPage from "components/components/content-page"

const termsMarkdown = require('data/terms-of-use.md');

const TermsOfUse = () => {
  return (
    <Layout>
      <ContentPage
        surtitle="Netspeak Games"
        title="Terms of use"
        content={termsMarkdown.default}
        />
    </Layout>
  )
}

export default TermsOfUse
